import React, { useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import TreeView from './Tree.js'
import redux from '../redux/index.js'
import { canOpenDocument, isSelected, openDocument, sortFileItems } from '../util.js'
import { makeSelectorUserdata } from '../redux/login/selectors.js'
import SearchInput from './Search.js'

const constructPayload = (pdsFields, rest) => {
  // eslint-disable-next-line
  const {
    city,
    address,
    name,
    email,
    homepage,
    phone,
    collection_name: collectionName,
    object_type_name: objectTypeName,
    postal_code: postalCode
  } = pdsFields

  return {
    ...rest,
    pds_user: name ?? '',
    pds_email: email ?? '',
    pds_phone: phone,
    pds_city: city ?? '',
    pds_address: address ?? '',
    pds_userhomesite: homepage ?? '',
    pds_contact: collectionName ?? '',
    pds_title: objectTypeName ?? '',
    pds_zipcode: postalCode ?? ''
  }
}

const FileView = ({
  strings,
  selected,
  selectedFileNode,
  expand,
  items,
  onSetSelectedFolder,
  onGetRootFolder,
  onGetSubFolders,
  onSetSelectedFile,
  searchValue,
  searchResults,
  isSearching,
  showSearchResults,
  onSearchValueChange,
  onStartSearch,
  onResetSearch,
  ...rest
}) => {
  const selectUserdata = useMemo(makeSelectorUserdata, [])
  const userdata = useSelector((state) => selectUserdata(state))

  const onSetSelectedItem = (e, fileItem) => {
    if (!userdata.sc_root) {
      return
    }
    if (fileItem.type === 'rootfolder') {
      onSetSelectedFolder(fileItem)
      onGetRootFolder(selected.current)
    } else if (fileItem.type === 'folder') {
      onSetSelectedFolder(fileItem)
      if (!expand[fileItem.id]) {
        onGetSubFolders(selected.current, fileItem)
      }
    } else if (fileItem.type === 'file') {
      onSetSelectedFile(fileItem)
      if (e === null) {
        const payload = constructPayload(userdata, rest)
        canOpenDocument(fileItem.uri) && openDocument(fileItem.uri, payload)
      }
      if (e?.detail === 2) {
        const payload = constructPayload(userdata, rest)
        canOpenDocument(fileItem.uri) && openDocument(fileItem.uri, payload)
      }
    }
  }
  return (
    <>
      <SearchInput onSetSelectedItem={onSetSelectedItem} />
      {showSearchResults
        ? (
          <TreeView
            items={searchResults}
            expand={expand}
            sortItems={sortFileItems}
            isSelected={isSelected(selectedFileNode)}
            onItemSelected={onSetSelectedItem}
          />
          )
        : (
          <TreeView
            items={items}
            expand={expand}
            sortItems={sortFileItems}
            isSelected={isSelected(selectedFileNode)}
            onItemSelected={onSetSelectedItem}
          />
          )}
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    selectedFileNode,
    trees,
    searchValue,
    searchResults,
    showSearchResults,
    isSearching
  } = state.files
  const getFileMeta = (item) => {
    const DEFAULT = { expand: {}, items: [] }
    if (item) {
      return trees[`${item.type}:${item.id}`] || DEFAULT
    } else {
      return DEFAULT
    }
  }

  const { expand, items } = getFileMeta(state.selected.current)
  return {
    strings: state.locale.strings,
    selectedFileNode,
    selected: state.selected,
    expand,
    items,

    searchValue,
    searchResults,
    showSearchResults,
    isSearching
  }
}

const mapDispatchToProps = (dispatch) => {
  const { files } = redux.actions
  return {
    onSetSelectedFolder: (folderItem) => {
      dispatch(files.setSelectedFolder(folderItem))
    },
    onGetRootFolder: (siteItem) => {
      dispatch(files.getRootFolder(siteItem))
    },
    onGetSubFolders: (siteItem, folderItem) => {
      dispatch(files.getSubFolder(siteItem, folderItem))
    },
    onSetSelectedFile: (fileItem) => {
      dispatch(files.setSelectedFile(fileItem))
    },
    onSearchValueChange: (value) => {
      dispatch(files.setSearchValue(value))
    },
    onStartSearch: (current, searchValue) => {
      dispatch(files.searchFiles(current, searchValue))
    },
    onResetSearch: () => {
      dispatch(files.resetSearch())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileView)
