import { configureStore } from '@reduxjs/toolkit'

import { busy } from './busy/reducer.js'
import { contentType } from './content-type/reducer.js'
import { dialog } from './dialog/reducer.js'
import { error } from './error/reducer.js'
import { files } from './files/reducer.js'
import { locale } from './locale/reducer.js'
import { login } from './login/reducer.js'
import { progress } from './progress/reducer.js'
import { selected } from './selected/reducer.js'

const store = configureStore({
  reducer: {
    busy,
    contentType,
    dialog,
    error,
    files,
    locale: locale(),
    login: login(),
    progress,
    selected
  }
})

export default store
