export default Object.freeze({
  en: {
    attach: 'Attach',
    cancel: 'Cancel',
    change_sort: 'Sort Order',
    clear: 'Clear',
    close: 'Close',
    contact_page_title: 'Contacts',
    contact_person_title: 'Contact Persons',
    document_library: 'Document Library',
    email: 'Email',
    favorite: 'Favorite',
    explain_login: 'You don\'t have an account with PDS. Contact your PDS administrator or send en email to us to set it up for your organization.',
    filename: 'File name',
    file_search_clear: 'Clear Search',
    file_search_no_results: 'No Results.',
    fill_content_control: 'Fill in',
    intranet_page_title: 'Intranet',
    language: 'Language',
    loading: 'Loading...',
    login_error_retry_button: 'Reload page and try again',
    login_error_logout_button: 'Logout and try again',
    logout: 'Logout',
    logout_confirm: 'Are you sure you would like to sign out?',
    mailto_subject: 'I\'d like to order / set up PDS for my organization!',
    navigate_first_page: 'First Page',
    navigate_back_many: '-10',
    navigate_back: '-1',
    navigate_forward: '+1',
    navigate_forward_many: '+10',
    navigate_last_page: 'Last Page',
    no_options: 'No options',
    open: 'Open',
    oops_title: 'Whooops !',
    paste_person_data: 'Paste',
    people_dialog_title: 'People with roles',
    project_page_title: 'Projects',
    reply: 'Reply',
    reply_all: 'Reply All',
    save: 'Save',
    save_as: 'Save As',
    save_dialog_title: 'Save',
    save_attachments: 'Save Attachments',
    send_and_file: 'Send and File',
    session: 'Session',
    search_for_template: 'Search for template',
    settings_dialog_title: 'Settings',
    show_favorites: 'Favorites',
    show_recent: 'Recent',
    templates_page_title: 'Templates',
    templates_root_node: 'Templates',
    tenant: 'Tenant',
    user_name: 'Name',

    PROGRESS_TITLE_UPLOAD_FILE: 'Save',
    PROGRESS_STATUS_READ_FILE: 'Reading file data',
    PROGRESS_STATUS_WRITE_CUSTOM_XML: 'Writing custom xml',
    PROGRESS_STATUS_UPLOAD_FILE: 'Uploading to SharePoint',
    PROGRESS_STATUS_OPEN_FILE: 'Opening file on SharePoint. Please wait',

    PROGRESS_TITLE_SEND_AND_FILE: 'Send and File',
    PROGRESS_STATUS_SAVE_DRAFT_EMAIL: 'Saving draft',
    PROGRESS_STATUS_FETCH_EMAIL_WITH_RETRIES: 'Fetching draft email data from server. This can take some time. Please wait',
    PROGRESS_STATUS_SEND_DRAFT_EMAIL: 'Sending draft email',

    PROGRESS_TITLE_UPLOAD_ATTACHMENTS: 'Save Attachments',
    PROGRESS_STATUS_READ_ATTACHMENTS: 'Reading attachment file data from Outlook',
    PROGRESS_STATUS_UPLOAD_ATTACHMENTS: 'Uploading attachment %s to SharePoint',

    PROGRESS_TITLE_LOGOUT: 'Log out',
    PROGRESS_STATUS_LOGOUT: 'Logging out',

    PROGRESS_TITLE_ATTACH_FILE: 'Add Attachment',
    PROGRESS_STATUS_READ_SHAREPOINT_FILE: 'Reading file from SharePoint',
    PROGRESS_STATUS_ATTACH_FILE: 'Attaching file to email',

    // Error messages

    ERROR_TYPE_CATEGORY_GET: 'Failed to get intranet folders.',
    ERROR_TYPE_CATEGORY_NOT_FOUND: 'Could not find intranet folder.',
    ERROR_TYPE_CATEGORY_UPDATE: 'Failed to update intranet folder.',
    ERROR_TYPE_CATEGORY_NO_GET_PERMISSION: 'Not enough permissions to read intranet folder.',
    ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION: 'Not enough permissions to update intranet folder.',

    ERROR_TYPE_CONTACT_GET: 'Failed to get contacts.',
    ERROR_TYPE_CONTACT_NOT_FOUND: 'Could not find contact.',
    ERROR_TYPE_CONTACT_UPDATE: 'Failed to update contact.',
    ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Not enough permissions to read contact.',
    ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Not enough permissions to update contact.',

    ERROR_TYPE_CONTENT_TYPE_GET: 'Failed to get content types.',
    ERROR_TYPE_CONTENT_TYPE_NOT_FOUND: 'Could not find content type.',
    ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION: 'Not enough permissions to read content types.',

    ERROR_TYPE_DEPARTMENT_GET: 'Failed to get intranet sections.',
    ERROR_TYPE_DEPARTMENT_NOT_FOUND: 'Could not find intranet section.',
    ERROR_TYPE_DEPARTMENT_UPDATE: 'Failed to update intranet sections.',
    ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION: 'Not enough permissions to read intranet section.',
    ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION: 'Not enough permissions to update intranet section.',

    ERROR_TYPE_DOC_LIB_GET: 'Failed to get files from document library.',

    ERROR_TYPE_EMAIL_READ: 'Failed to read email.',
    ERROR_TYPE_EMAIL_SAVE_DRAFT: 'Failed to save draft email.',
    ERROR_TYPE_EMAIL_SEND: 'Failed to send email.',

    ERROR_TYPE_FILE_ATTACH: 'Failed to attach file.',
    ERROR_TYPE_FILE_READ: 'Failed to read file data.',
    ERROR_TYPE_FILE_READ_ATTACHMENTS: 'Failed to read file attachments.',
    ERROR_TYPE_FILE_SAVED_ADD: 'Failed to add file saved event.',
    ERROR_TYPE_FILE_SAVED_NO_ADD_PERMISSION: 'Not enough permissions to add file saved event.',
    ERROR_TYPE_FILE_SAVE: 'Failed to save file.',
    ERROR_TYPE_FILE_SEARCH: 'Searching for files failed.',
    ERROR_TYPE_FILE_UPLOAD: 'Failed to upload file.',
    ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS: 'Failed to upload file attachments.',

    ERROR_TYPE_PROJECT_GET: 'Failed to get projects.',
    ERROR_TYPE_PROJECT_NOT_FOUND: 'Could not find project.',
    ERROR_TYPE_PROJECT_UPDATE: 'Failed to update project.',
    ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'Not enough permissions to read project.',
    ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'Not enough permissions to update project.',

    // Generic errors.

    ERROR_TYPE_AUTH: 'Authentication error. Try to login again.',
    ERROR_TYPE_LOGIN: 'Failed to login. Contact your administrator and make sure you have an account registered with PDS.',
    ERROR_TYPE_NETWORK: 'Connection refused. Make sure you have a working internet connection.',
    ERROR_TYPE_UNKNOWN: 'Unknown error. Please try again later.'
  },
  sv: {
    attach: 'Bifoga',
    cancel: 'Avbryt',
    change_sort: 'Sortering',
    clear: 'Rensa',
    close: 'Stäng',
    contact_page_title: 'Kontakter',
    contact_person_title: 'Kontaktpersoner',
    document_library: 'Dokumentbibliotek',
    email: 'Email',
    favorite: 'Favorit',
    explain_login: 'Du har inget konto registrerat hos PDS. Kontakta din administratör för PDS eller skicka ett mejl till oss för att sätta upp det i din organisation.',
    filename: 'Filnamn',
    file_search_clear: 'Återställ',
    file_search_no_results: 'Inga resultat.',
    fill_content_control: 'Fyll i',
    intranet_page_title: 'Intranät',
    language: 'Språk',
    loading: 'Laddar...',
    login_error_retry_button: 'Ladda om sidan och försök igen',
    login_error_logout_button: 'Logga ut och försök igen',
    logout: 'Logga ut',
    logout_confirm: 'Är du säker på att du vill logga ut?',
    mailto_subject: 'Jag vill beställa/sätta upp PDS i min organisation!',
    navigate_first_page: 'Första sidan',
    navigate_back_many: '-10',
    navigate_back: '-1',
    navigate_forward: '+1',
    navigate_forward_many: '+10',
    navigate_last_page: 'Sista sidan',
    no_options: 'Inga resultat',
    open: 'Öppna',
    oops_title: 'Hoppsan !',
    paste_person_data: 'Klistra in',
    people_dialog_title: 'Personer med roller',
    project_page_title: 'Projekt',
    reply: 'Svara',
    reply_all: 'Svara alla',
    save: 'Spara',
    save_as: 'Spara Som',
    save_dialog_title: 'Spara',
    save_attachments: 'Spara bilagor',
    send_and_file: 'Skicka och spara',
    session: 'Session',
    search_for_template: 'Sök efter mall',
    settings_dialog_title: 'Inställningar',
    show_favorites: 'Favoriter',
    show_recent: 'Senaste',
    templates_page_title: 'Mallhanteraren',
    templates_root_node: 'Mallar',
    tenant: 'Tenant',
    user_name: 'Namn',

    PROGRESS_TITLE_UPLOAD_FILE: 'Spara',
    PROGRESS_STATUS_READ_FILE: 'Läser fildata',
    PROGRESS_STATUS_WRITE_CUSTOM_XML: 'Skriver egenskaper till xml',
    PROGRESS_STATUS_UPLOAD_FILE: 'Laddar upp till SharePoint',
    PROGRESS_STATUS_OPEN_FILE: 'Öppnar filen från SharePoint. Var god vänta',

    PROGRESS_TITLE_SEND_AND_FILE: 'Skicka och spara',
    PROGRESS_STATUS_SAVE_DRAFT_EMAIL: 'Sparar utkast',
    PROGRESS_STATUS_FETCH_EMAIL_WITH_RETRIES: 'Hämtar utkast från server. Detta kan ta lite tid. Var god vänta',
    PROGRESS_STATUS_SEND_DRAFT_EMAIL: 'Skickar utkast',

    PROGRESS_TITLE_UPLOAD_ATTACHMENTS: 'Spara bilagor',
    PROGRESS_STATUS_READ_ATTACHMENTS: 'Läser in bilagor från Outlook',
    PROGRESS_STATUS_UPLOAD_ATTACHMENTS: 'Laddar upp bilaga %s till SharePoint',

    PROGRESS_TITLE_LOGOUT: 'Logga ut',
    PROGRESS_STATUS_LOGOUT: 'Loggar ut',

    PROGRESS_TITLE_ATTACH_FILE: 'Lägg till bilaga',
    PROGRESS_STATUS_READ_SHAREPOINT_FILE: 'Läser fildata från SharePoint',
    PROGRESS_STATUS_ATTACH_FILE: 'Lägger till bilaga',

    ERROR_TYPE_CATEGORY_GET: 'Kunde inte hämta intranätsmappar.',
    ERROR_TYPE_CATEGORY_NOT_FOUND: 'Intranätsmapp saknas.',
    ERROR_TYPE_CATEGORY_UPDATE: 'Kunde inte ändra intranätsmapp.',
    ERROR_TYPE_CATEGORY_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa intranätsmapp.',
    ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra intranätsmapp.',

    ERROR_TYPE_CONTACT_GET: 'Kunde inte hämta kontakter.',
    ERROR_TYPE_CONTACT_NOT_FOUND: 'Kontakten saknas.',
    ERROR_TYPE_CONTACT_UPDATE: 'Kunde inte ändra kontakten.',
    ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa kontakt.',
    ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra kontakt.',

    ERROR_TYPE_CONTENT_TYPE_GET: 'Kunde inte hämta innehållstyper.',
    ERROR_TYPE_CONTENT_TYPE_NOT_FOUND: 'Innehållstypen saknas.',
    ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa innehållstyper.',

    ERROR_TYPE_DEPARTMENT_GET: 'Kunde inte hämta intranätsdelar.',
    ERROR_TYPE_DEPARTMENT_NOT_FOUND: 'Intranätsdelen saknas.',
    ERROR_TYPE_DEPARTMENT_UPDATE: 'Kunde inte ändra intranätsdelen.',
    ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa intranätsdel.',
    ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra intranätsdel.',

    ERROR_TYPE_DOC_LIB_GET: 'Kunde inte hämta filer från dokumentbibliotek.',

    ERROR_TYPE_EMAIL_READ: 'Kunde inte läsa mejl.',
    ERROR_TYPE_EMAIL_SAVE_DRAFT: 'Kunde inte spara mejlutkast.',
    ERROR_TYPE_EMAIL_SEND: 'Kunde inte skicka mejl.',

    ERROR_TYPE_FILE_ATTACH: 'Kunde inte lägga till bilaga.',
    ERROR_TYPE_FILE_READ: 'Kunde inte läsa fildata.',
    ERROR_TYPE_FILE_READ_ATTACHMENTS: 'Kunde inte läsa filbilagor.',
    ERROR_TYPE_FILE_SAVED_ADD: 'Kunde inte lägga till filevent.',
    ERROR_TYPE_FILE_SAVED_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till filevent.',
    ERROR_TYPE_FILE_SAVE: 'Kunde inte spara filen.',
    ERROR_TYPE_FILE_SEARCH: 'Kunde inte söka efter filer.',
    ERROR_TYPE_FILE_UPLOAD: 'Kunde inte ladda upp filen.',
    ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS: 'Kunde inte ladda upp filbilagor.',

    ERROR_TYPE_PROJECT_GET: 'Kunde inte hämta projekt.',
    ERROR_TYPE_PROJECT_NOT_FOUND: 'Projektet saknas.',
    ERROR_TYPE_PROJECT_UPDATE: 'Kunde inte ändra projektet.',
    ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa projekt.',
    ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra projekt.',

    // Generic errors.

    ERROR_TYPE_AUTH: 'Autentiseringsfel. Prova att logga in igen.',
    ERROR_TYPE_LOGIN: 'Kunde inte logga in. Kontakta din administrator och se till att du har ett konto registrerat hos PDS.',
    ERROR_TYPE_NETWORK: 'Nätverksproblem. Se till att du har en fungerande internetuppkoppling.',
    ERROR_TYPE_UNKNOWN: 'Okänt problem. Var god försök igen om en stund.'
  },
  sv_lawfirm: {
    attach: 'Bifoga',
    cancel: 'Avbryt',
    change_sort: 'Sortering',
    clear: 'Rensa',
    close: 'Stäng',
    contact_page_title: 'Klienter',
    contact_person_title: 'Kontaktpersoner',
    document_library: 'Dokumentbibliotek',
    email: 'Email',
    favorite: 'Favorit',
    explain_login: 'Du har inget konto registrerat hos PDS. Kontakta din administratör för PDS eller skicka ett mejl till oss för att sätta upp det i din organisation.',
    filename: 'Filnamn',
    file_search_clear: 'Återställ',
    file_search_no_results: 'Inga resultat.',
    fill_content_control: 'Fyll i',
    intranet_page_title: 'Intranät',
    language: 'Språk',
    loading: 'Laddar...',
    login_error_retry_button: 'Ladda om sidan och försök igen',
    login_error_logout_button: 'Logga ut och försök igen',
    logout: 'Logga ut',
    logout_confirm: 'Är du säker på att du vill logga ut?',
    mailto_subject: 'Jag vill beställa/sätta upp PDS i min organisation!',
    navigate_first_page: 'Första sidan',
    navigate_back_many: '-10',
    navigate_back: '-1',
    navigate_forward: '+1',
    navigate_forward_many: '+10',
    navigate_last_page: 'Sista sidan',
    no_options: 'Inga resultat',
    open: 'Öppna',
    oops_title: 'Hoppsan !',
    paste_person_data: 'Klistra in',
    people_dialog_title: 'Personer med roller',
    project_page_title: 'Ärenden',
    reply: 'Svara',
    reply_all: 'Svara alla',
    save: 'Spara',
    save_as: 'Spara Som',
    save_dialog_title: 'Spara',
    save_attachments: 'Spara bilagor',
    send_and_file: 'Skicka och spara',
    session: 'Session',
    search_for_template: 'Sök efter mall',
    settings_dialog_title: 'Inställningar',
    show_favorites: 'Favoriter',
    show_recent: 'Senaste',
    templates_page_title: 'Mallhanteraren',
    templates_root_node: 'Mallar',
    tenant: 'Tenant',
    user_name: 'Namn',

    PROGRESS_TITLE_UPLOAD_FILE: 'Spara',
    PROGRESS_STATUS_READ_FILE: 'Läser fildata',
    PROGRESS_STATUS_WRITE_CUSTOM_XML: 'Skriver egenskaper till xml',
    PROGRESS_STATUS_UPLOAD_FILE: 'Laddar upp till SharePoint',
    PROGRESS_STATUS_OPEN_FILE: 'Öppnar filen från SharePoint. Var god vänta',

    PROGRESS_TITLE_SEND_AND_FILE: 'Skicka och spara',
    PROGRESS_STATUS_SAVE_DRAFT_EMAIL: 'Sparar utkast',
    PROGRESS_STATUS_FETCH_EMAIL_WITH_RETRIES: 'Hämtar utkast från server. Detta kan ta lite tid. Var god vänta',
    PROGRESS_STATUS_SEND_DRAFT_EMAIL: 'Skickar utkast',

    PROGRESS_TITLE_UPLOAD_ATTACHMENTS: 'Spara bilagor',
    PROGRESS_STATUS_READ_ATTACHMENTS: 'Läser in bilagor från Outlook',
    PROGRESS_STATUS_UPLOAD_ATTACHMENTS: 'Laddar upp bilaga %s till SharePoint',

    PROGRESS_TITLE_LOGOUT: 'Logga ut',
    PROGRESS_STATUS_LOGOUT: 'Loggar ut',

    PROGRESS_TITLE_ATTACH_FILE: 'Lägg till bilaga',
    PROGRESS_STATUS_READ_SHAREPOINT_FILE: 'Läser fildata från SharePoint',
    PROGRESS_STATUS_ATTACH_FILE: 'Lägger till bilaga',

    ERROR_TYPE_CATEGORY_GET: 'Kunde inte hämta intranätsmappar.',
    ERROR_TYPE_CATEGORY_NOT_FOUND: 'Intranätsmapp saknas.',
    ERROR_TYPE_CATEGORY_UPDATE: 'Kunde inte ändra intranätsmapp.',
    ERROR_TYPE_CATEGORY_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa intranätsmapp.',
    ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra intranätsmapp.',

    ERROR_TYPE_CONTACT_GET: 'Kunde inte hämta klienter.',
    ERROR_TYPE_CONTACT_NOT_FOUND: 'Klienten saknas.',
    ERROR_TYPE_CONTACT_UPDATE: 'Kunde inte ändra klienten.',
    ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa klient.',
    ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra klient.',

    ERROR_TYPE_CONTENT_TYPE_GET: 'Kunde inte hämta innehållstyper.',
    ERROR_TYPE_CONTENT_TYPE_NOT_FOUND: 'Innehållstypen saknas.',
    ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa innehållstyper.',

    ERROR_TYPE_DEPARTMENT_GET: 'Kunde inte hämta intranätsdelar.',
    ERROR_TYPE_DEPARTMENT_NOT_FOUND: 'Intranätsdelen saknas.',
    ERROR_TYPE_DEPARTMENT_UPDATE: 'Kunde inte ändra intranätsdelen.',
    ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa intranätsdel.',
    ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra intranätsdel.',

    ERROR_TYPE_DOC_LIB_GET: 'Kunde inte hämta filer från dokumentbibliotek.',

    ERROR_TYPE_EMAIL_READ: 'Kunde inte läsa mejl.',
    ERROR_TYPE_EMAIL_SAVE_DRAFT: 'Kunde inte spara mejlutkast.',
    ERROR_TYPE_EMAIL_SEND: 'Kunde inte skicka mejl.',

    ERROR_TYPE_FILE_ATTACH: 'Kunde inte lägga till bilaga.',
    ERROR_TYPE_FILE_READ: 'Kunde inte läsa fildata.',
    ERROR_TYPE_FILE_READ_ATTACHMENTS: 'Kunde inte läsa filbilagor.',
    ERROR_TYPE_FILE_SAVED_ADD: 'Kunde inte lägga till filevent.',
    ERROR_TYPE_FILE_SAVED_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till filevent.',
    ERROR_TYPE_FILE_SAVE: 'Kunde inte spara filen.',
    ERROR_TYPE_FILE_SEARCH: 'Kunde inte söka efter filer.',
    ERROR_TYPE_FILE_UPLOAD: 'Kunde inte ladda upp filen.',
    ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS: 'Kunde inte ladda upp filbilagor.',

    ERROR_TYPE_PROJECT_GET: 'Kunde inte hämta ärenden.',
    ERROR_TYPE_PROJECT_NOT_FOUND: 'Ärendet saknas.',
    ERROR_TYPE_PROJECT_UPDATE: 'Kunde inte ändra ärendet.',
    ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa ärende.',
    ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra ärende.',

    // Generic errors.

    ERROR_TYPE_AUTH: 'Autentiseringsfel. Prova att logga in igen.',
    ERROR_TYPE_LOGIN: 'Kunde inte logga in. Kontakta din administrator och se till att du har ett konto registrerat hos PDS.',
    ERROR_TYPE_NETWORK: 'Nätverksproblem. Se till att du har en fungerande internetuppkoppling.',
    ERROR_TYPE_UNKNOWN: 'Okänt problem. Var god försök igen om en stund.'
  }
})
