const config = Object.freeze({
  api: {
    baseUrl: process.env.REACT_APP_API_URL
  },
  msal: {
    auth: {
      clientId: '4bd39bf5-492c-45ae-95c9-e4cf826e46eb'
    },
    cache: {
      cacheLocation: 'sessionStorage'
    }
  },
  outlook: {
    readRetryCount: 30,
    readRetryTimeout: 2000
  },
  autoLoginTimeout: 300,
  errorTimeout: 7000,
  waitForSaveTimeout: 5000
})

export default config
