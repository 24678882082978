const APP_NAME = `pds.tm.${process.env.NODE_ENV}`
const USER_DATA_KEY = `${APP_NAME}.userData`
const LOCALE_KEY = `${APP_NAME}.locale`
const PAGE_KEY = `${APP_NAME}.page`
const PROJECT_RECENT_KEY = `${APP_NAME}.project.recent`
const PROJECT_FAVORITES_KEY = `${APP_NAME}.project.favorites`
const CONTACT_RECENT_KEY = `${APP_NAME}.contact.recent`
const CONTACT_FAVORITES_KEY = `${APP_NAME}.contact.favorites`
const DEPARTMENT_RECENT_KEY = `${APP_NAME}.department.recent`
const DEPARTMENT_FAVORITES_KEY = `${APP_NAME}.department.favorites`

const storage = {
  // user data
  getUserData () {
    return {
      // From pds-api
      email: localStorage.getItem(`${USER_DATA_KEY}.email`),
      name: localStorage.getItem(`${USER_DATA_KEY}.name`),
      employee_id: localStorage.getItem(`${USER_DATA_KEY}.employee_id`),
      address: localStorage.getItem(`${USER_DATA_KEY}.address`),
      postal_code: localStorage.getItem(`${USER_DATA_KEY}.postal_code`),
      city: localStorage.getItem(`${USER_DATA_KEY}.city`),
      homepage: localStorage.getItem(`${USER_DATA_KEY}.homepage`),
      phone: localStorage.getItem(`${USER_DATA_KEY}.phone`),
      collection_name: localStorage.getItem(`${USER_DATA_KEY}.collection_name`),
      object_type_name: localStorage.getItem(`${USER_DATA_KEY}.object_type_name`),

      permissions: localStorage.getItem(`${USER_DATA_KEY}.permissions`),
      sc_root: localStorage.getItem(`${USER_DATA_KEY}.sc_root`),
      tenant_name: localStorage.getItem(`${USER_DATA_KEY}.tenant_name`),

      // PDS api token
      token: sessionStorage.getItem(`${USER_DATA_KEY}.token`) || '',
      // From Microsoft, via msal-browser
      idToken: sessionStorage.getItem(`${USER_DATA_KEY}.idToken`) || '',
      accessToken: sessionStorage.getItem(`${USER_DATA_KEY}.accessToken`) || ''
    }
  },
  putUserData (userData) {
    localStorage.setItem(`${USER_DATA_KEY}.email`, userData.email)
    localStorage.setItem(`${USER_DATA_KEY}.employee_id`, userData.employee_id)
    localStorage.setItem(`${USER_DATA_KEY}.name`, userData.name)
    localStorage.setItem(`${USER_DATA_KEY}.address`, userData.address)
    localStorage.setItem(`${USER_DATA_KEY}.postal_code`, userData.postal_code)
    localStorage.setItem(`${USER_DATA_KEY}.city`, userData.city)
    localStorage.setItem(`${USER_DATA_KEY}.homepage`, userData.homepage)
    localStorage.setItem(`${USER_DATA_KEY}.phone`, userData.phone)
    localStorage.setItem(`${USER_DATA_KEY}.collection_name`, userData.collection_name)
    localStorage.setItem(`${USER_DATA_KEY}.object_type_name`, userData.object_type_name)

    localStorage.setItem(`${USER_DATA_KEY}.permissions`, userData.permissions)
    localStorage.setItem(`${USER_DATA_KEY}.sc_root`, userData.sc_root)
    localStorage.setItem(`${USER_DATA_KEY}.tenant_name`, userData.tenant_name)

    sessionStorage.setItem(`${USER_DATA_KEY}.token`, userData.token)
    sessionStorage.setItem(`${USER_DATA_KEY}.idToken`, userData.idToken)
    sessionStorage.setItem(`${USER_DATA_KEY}.accessToken`, userData.accessToken || '')
  },
  deleteUserData () {
    localStorage.removeItem(`${USER_DATA_KEY}.email`)
    localStorage.removeItem(`${USER_DATA_KEY}.name`)
    localStorage.removeItem(`${USER_DATA_KEY}.address`)
    localStorage.removeItem(`${USER_DATA_KEY}.postal_code`)
    localStorage.removeItem(`${USER_DATA_KEY}.city`)
    localStorage.removeItem(`${USER_DATA_KEY}.homepage`)
    localStorage.removeItem(`${USER_DATA_KEY}.phone`)
    localStorage.removeItem(`${USER_DATA_KEY}.collection_name`)
    localStorage.removeItem(`${USER_DATA_KEY}.object_type_name`)
    localStorage.removeItem(`${USER_DATA_KEY}.employee_id`)

    localStorage.removeItem(`${USER_DATA_KEY}.permissions`)
    localStorage.removeItem(`${USER_DATA_KEY}.sc_root`)
    localStorage.removeItem(`${USER_DATA_KEY}.tenant_name`)

    sessionStorage.removeItem(`${USER_DATA_KEY}.token`)
    sessionStorage.removeItem(`${USER_DATA_KEY}.idToken`)
    sessionStorage.removeItem(`${USER_DATA_KEY}.accessToken`)
  },
  clearSession () {
    sessionStorage.clear()
  },

  // locale
  getLocale () {
    return localStorage.getItem(LOCALE_KEY)
  },
  putLocale (locale) {
    localStorage.setItem(LOCALE_KEY, locale)
  },

  // page
  getPage () {
    return localStorage.getItem(PAGE_KEY) || 'contact'
  },
  putPage (page) {
    localStorage.setItem(PAGE_KEY, page)
  },

  // Contact settings

  getContactRecent () {
    return Boolean(JSON.parse(localStorage.getItem(CONTACT_RECENT_KEY)))
  },
  putContactRecent (show) {
    localStorage.setItem(CONTACT_RECENT_KEY, JSON.stringify(show))
  },
  getContactFavorites () {
    return Boolean(JSON.parse(localStorage.getItem(CONTACT_FAVORITES_KEY)))
  },
  putContactFavorites (show) {
    localStorage.setItem(CONTACT_FAVORITES_KEY, JSON.stringify(show))
  },

  // Project settings

  getProjectRecent () {
    return Boolean(JSON.parse(localStorage.getItem(PROJECT_RECENT_KEY)))
  },
  putProjectRecent (show) {
    localStorage.setItem(PROJECT_RECENT_KEY, JSON.stringify(show))
  },
  getProjectFavorites () {
    return Boolean(JSON.parse(localStorage.getItem(PROJECT_FAVORITES_KEY)))
  },
  putProjectFavorites (show) {
    localStorage.setItem(PROJECT_FAVORITES_KEY, JSON.stringify(show))
  },

  // Department settings

  getDepartmentRecent () {
    return Boolean(JSON.parse(localStorage.getItem(DEPARTMENT_RECENT_KEY)))
  },
  putDepartmentRecent (show) {
    localStorage.setItem(DEPARTMENT_RECENT_KEY, JSON.stringify(show))
  },
  getDepartmentFavorites () {
    return Boolean(JSON.parse(localStorage.getItem(DEPARTMENT_FAVORITES_KEY)))
  },
  putDepartmentFavorites (show) {
    localStorage.setItem(DEPARTMENT_FAVORITES_KEY, JSON.stringify(show))
  }
}

export default storage
