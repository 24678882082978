import { useMemo, useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api } from 'domains'
import { handleGetErrors } from 'domains/error/actions'
import { makeSelectorByAuthTokens } from 'domains/login/selectors'

// { query: [{ key: 'id', equal: true, value: id }] }
export function useGetQuery (type, params, autoFetch = true) {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(undefined)
  const [data, setData] = useState(null)
  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])

  const authTokens = useSelector(selectAuthTokens)

  const effects = useCallback((params) => {
    setStatus('pending')
    if (type === 'project') {
      api.getProjects({ query: params }, authTokens).then(result => {
        if (result.data.value && result.data.value.length > 0) {
          setData(result.data.value[0])
        }
        setStatus('fulfilled')
      }).catch(() => {
        handleGetErrors('project', dispatch)
        setStatus('rejected')
      })
    } else if (type === 'contact') {
      api.getContacts({ query: params }, authTokens).then(result => {
        if (result.data.value && result.data.value.length > 0) {
          setData(result.data.value[0])
        }
        setStatus('fulfilled')
      }).catch(() => {
        handleGetErrors('project', dispatch)
        setStatus('rejected')
      })
    }
  }, [dispatch, setData, setStatus, authTokens])

  useEffect(() => {
    if (type && params && autoFetch === true && status === undefined) {
      effects(params)
    }
  }, [effects, params, status, autoFetch])

  const refetch = useCallback((params) => {
    effects(params)
  }, [effects])

  const isLoading = status === 'pending'
  const isError = status === 'rejected'
  const isSuccessful = status === 'fulfilled'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}
