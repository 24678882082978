import msalClient from '../../lib/msal.js'
import { decodeToken } from '../../util.js'
import parseUrl from 'url-parse'
import storage from '../storage.js'
import {
  ERROR_ACTION,
  resetError,
  unknownError,
  networkError
} from '../error/actions.js'
import { busy, notBusy } from '../busy/actions.js'
import { hideDialog } from '../dialog/actions.js'
import { beginProgressDialog } from '../progress/actions.js'

export const LOGIN_SUCCESS_ACTION = 'LOGIN_SUCCESS_ACTION'
export const ERROR_TYPE_LOGIN = 'ERROR_TYPE_LOGIN'

/**
 * Different messages during failed login.
 */
export const ERROR_LOGIN_NO_ACCOUNT = 'ERROR_LOGIN_NO_ACCOUNT'
export const ERROR_LOGIN_ACCESS_TOKEN = 'ERROR_LOGIN_ACCESS_TOKEN'
export const ERROR_LOGIN_GRAPH_ACCESS_TOKEN = 'ERROR_LOGIN_GRAPH_ACCESS_TOKEN'

const HEARTBEATS_PER_MINUTE = 4
const HEARTBEAT_INTERVAL = 60 / HEARTBEATS_PER_MINUTE * 1000
const RELOGIN_TIME_MS = 3 * 60 * 1000

export function initLoginActions (api, store) {
  /**
   * Login heart beat function.
   */
  function doStartHeartbeat () {
    let heartBeatInterval = null
    let checkTokenCounter = -1

    return function (dispatch) {
      if (heartBeatInterval) {
        console.info('clearing old heartbeat!')
        clearInterval(heartBeatInterval)
        heartBeatInterval = null
        checkTokenCounter = -1
      }

      console.info('starting login heartbeat!')

      function shouldLog () {
        // Log every 5 minutes
        return checkTokenCounter % (5 * HEARTBEATS_PER_MINUTE) === 0
      }

      function tokenTimingOut (token, name) {
        const payload = decodeToken(token)
        const timeLeft = payload.exp * 1000 - Date.now()
        if (shouldLog()) {
          console.log(` ${name} - time left: ${timeLeft / 1000 / 60} minutes`)
        }
        return timeLeft < RELOGIN_TIME_MS
      }

      function reload () {
        storage.clearSession()
        console.info('RELOADING WINDOW.LOCATION!')
        window.location.reload()
      }

      function heartBeat () {
        const state = store.getState()
        const userData = state.login.userData
        const {
          idToken,
          token,
          accessToken
        } = userData

        if (state.busy) return

        ++checkTokenCounter

        if (shouldLog()) console.log('Heartbeat log:')

        if (idToken && tokenTimingOut(idToken, 'id token')) {
          return reload()
        }

        if (token && tokenTimingOut(token, 'api token')) {
          return reload()
        }

        if (accessToken && tokenTimingOut(accessToken, 'access token')) {
          return reload()
        }
      }

      heartBeatInterval = setInterval(heartBeat, HEARTBEAT_INTERVAL)
      heartBeat()
    }
  }

  /**
   * idToken is passed in and is always valid. The next steps
   * ensures that api token and access token are also valid.
   */
  function doLogin (idToken) {
    return function (dispatch) {
      const msal = msalClient()

      dispatch(resetError())

      /**
       * Fetch a new api token.
       */
      function apiTokenStep (idToken) {
        dispatch(busy())
        api.login(idToken).then(async (result) => {
          dispatch(notBusy())
          const userData = result.data.value
          userData.idToken = idToken

          const payload = decodeToken(idToken)
          const account = msal.getAccountByUsername(payload.preferred_username)

          const scRoot = userData.sc_root
          if (typeof scRoot === 'string' && scRoot.startsWith('https://')) {
            try {
              userData.accessToken = await getSpAccessToken(userData.sc_root, account)
            } catch (err) {
              console.error('getSpAccessToken() failed', err)
              return dispatch(loginError(ERROR_LOGIN_ACCESS_TOKEN))
            }
          } else {
            console.error('missing sharepoint root when sharepoint features should be enabled!')
          }
          dispatch(loginSuccessful(userData))
        }).catch(err => {
          console.error('api.login() failed', err)
          dispatch(notBusy())
          const { response } = err
          if (response) {
            if (response.status === 401) {
              dispatch(loginError(ERROR_LOGIN_NO_ACCOUNT))
            } else {
              dispatch(unknownError(err))
            }
          } else {
            dispatch(networkError(err))
          }
        })
      }

      /**
       * Fetch an access token for SharePoint
       */
      function getSpAccessToken (scRoot, account) {
        const url = parseUrl(scRoot)
        const spHost = `${url.protocol}//${url.host}`
        return getAccessToken({
          scopes: [`${spHost}//.default`],
          account
        })
      }

      /**
       * Common access token function.
       */
      async function getAccessToken (request, cb) {
        try {
          console.info('msal.acquireTokenSilent()', request)
          const result = await msal.acquireTokenSilent(request)
          return result.accessToken
        } catch (err) {
          console.warn('msal.acquireTokenSilent failed', err)
          console.log('trying aquireTokenRedirect')
          // This happens when the user must give consent.
          // The next time around the silent call will succeed.
          return msal.acquireTokenRedirect(request)
        }
      }

      apiTokenStep(idToken)
    }
  }

  function loginSuccessful (ud) {
    const userData = Object.assign({}, ud)
    console.info('login successful!', userData)
    setTimeout(() => {
      storage.putUserData(userData)
    }, 50)
    return {
      type: LOGIN_SUCCESS_ACTION,
      userData
    }
  }

  function loginError (message) {
    return {
      type: ERROR_ACTION,
      errorType: ERROR_TYPE_LOGIN,
      errorMessage: message
    }
  }

  /**
   * Logs out the current user from Microsoft and clears cached data.
   */
  function doLogout () {
    return function (dispatch) {
      const msal = msalClient()
      const { idToken } = store.getState().login.userData
      const payload = decodeToken(idToken)
      const account = msal.getAccountByUsername(payload.preferred_username)
      dispatch(hideDialog())
      dispatch(
        beginProgressDialog(
          'PROGRESS_TITLE_LOGOUT',
          'PROGRESS_STATUS_LOGOUT'
        )
      )
      storage.deleteUserData()
      msal.logout({ account })
    }
  }

  return {
    doStartHeartbeat,
    doLogin,
    doLogout
  }
}
