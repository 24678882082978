import store from './create-store.js'
import createClient from '@pergas-pds/pds-api-client'
import { initContentTypeActions } from './content-type/actions.js'
import { resetError } from './error/actions.js'
import {
  setSelectedFolder,
  setSelectedFile,
  resetSelectedFile,
  setSearchValue,
  resetSearch,
  initFileActions
} from './files/actions.js'
import { changeLocale } from './locale/actions.js'
import { initLoginActions } from './login/actions.js'
import {
  beginProgressDialog,
  updateProgressDialog,
  endProgressDialog
} from './progress/actions.js'
import config from '../config.js'
import { resetSelectedItem, setSelectedItem } from './selected/actions.js'

export const api = createClient(config.api)
const {
  getRootFolder,
  getTemplatesRootFolder,
  getSubFolder,
  attachFile,
  searchFiles
} = initFileActions(store)

const redux = {
  store,
  actions: {
    selected: {
      setSelectedItem: setSelectedItem,
      resetSelectedItem: resetSelectedItem
    },
    contentType: initContentTypeActions(api, store),
    error: { resetError },
    files: {
      setSelectedFolder,
      setSelectedFile,
      resetSelectedFile,
      setSearchValue,
      resetSearch,
      getRootFolder,
      getTemplatesRootFolder,
      getSubFolder,
      attachFile,
      searchFiles
    },
    locale: { changeLocale },
    login: initLoginActions(api, store),
    progress: {
      beginProgressDialog,
      updateProgressDialog,
      endProgressDialog
    }
  }
}

export default redux
