import redux from './redux'
import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { theme, ThemeProvider } from '@pergas-common/pergas-components'
import { Provider } from 'react-redux'
import './index.css'
import App from './App.js'
import msalClient from './lib/msal.js'
import storage from './redux/storage.js'
import { decodeToken, isValidToken } from './util.js'

Modal.setAppElement('#root-modal')
const start = (idToken) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={redux.store}>
        <ThemeProvider theme={theme}>
          <App idToken={idToken} />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

const { idToken, email } = redux.store.getState().login.userData

/**
 * Main login method to receive id token
 * 1. when the user hasn't logged in before, or
 * 2. when ssoSilent() fails
 */
function redirectLogin (msal) {
  msal.handleRedirectPromise().then((tokenResponse) => {
    if (tokenResponse !== null) {
      console.info('got id token from redirect method')
      start(tokenResponse.idToken)
    } else {
      try {
        console.info('initiating redirect method')
        msal.loginRedirect()
      } catch (err) {
        // TODO this is a fatal error -> rende Error page with details
        console.error('msal.loginRedirect failed')
      }
    }
  }).catch((err) => {
    // TODO this is a fatal error -> rende Error page with details
    console.error('msal.handleRedirectPromise failed', err)
  })
}

if (isValidToken(idToken)) {
  console.info('found cached valid id token')
  start(idToken)
} else {
  const msal = msalClient()
  const payload = decodeToken(idToken)
  const loginHint = payload.preferred_username || email

  if (typeof loginHint === 'string') {
    console.info('msal.ssoSilent() with login hint', loginHint)
    const silentRequest = { loginHint }
    msal.ssoSilent(silentRequest).then(result => {
      start(result.idToken)
    }).catch((err) => {
      console.error('msal.ssoSilent failed', err)
      storage.deleteUserData()
      redirectLogin(msal)
    })
  } else {
    redirectLogin(msal)
  }
}
