import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorByAuthTokens = () => createSelector(
  (state) => state.login,
  (slice) => {
    const { token, idToken, accessToken } = slice.userData
    return {
      token,
      idToken,
      accessToken
    }
  }
)

export const makeSelectorUserdata = () => createSelector(
  (state) => state.login,
  (slice) => {
    return slice.userData
  }
)
