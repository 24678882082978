import React from 'react'
import { Excel, Folder, Word, Powerpoint } from '@pergas-common/pergas-icons'
import parseUrl from 'url-parse'
import { replace } from './lib/encoding'

/**
 * Common file type extensions.
 */
export const EXCEL_FILES = ['xls', 'xlsx']
export const EXCEL_TEMPLATE_FILES = ['xlt', 'xltm', 'xltx']
export const POWERPOINT_FILES = ['ppt', 'pptx']
export const POWERPOINT_TEMPLATE_FILES = ['pot', 'potm', 'potx']
export const WORD_FILES = ['doc', 'docx']
export const WORD_TEMPLATE_FILES = ['dot', 'dotm', 'dotx']
export const TEMPLATE_FILES = EXCEL_TEMPLATE_FILES
  .concat(WORD_TEMPLATE_FILES)
  .concat(POWERPOINT_TEMPLATE_FILES)

/**
 * Adds https:// to an url if it's missing (for hrefs)
 */
export function httpify (url) {
  const lower = url.toLowerCase()
  if (lower.startsWith('http://') || lower.startsWith('https://')) {
    return url
  } else {
    return `https://${url}`
  }
}

/**
 * Compares two strings in lower case.
 */
function compareLowerCase (A, B) {
  const a = A.toLowerCase()
  const b = B.toLowerCase()
  if (a < b) return -1
  else if (a > b) return 1
  else return 0
}

/**
 * Sorts an array of folders and files.
 * Folders are sorted before files.
 * Folder and files are sorted between each other.
 */
export function sortFileItems (items) {
  return items.slice().sort((A, B) => {
    if (A.type === 'folder' && B.type === 'file') {
      return -1
    } else if (A.type === 'file' && B.type === 'folder') {
      return 1
    }
    return compareLowerCase(A.name, B.name)
  })
}

/**
 * Check if a token has expired or not.
 */
export function isValidToken (token) {
  return typeof token === 'string' && getTokenExpiresInMs(token) > 0
}

function getTokenExpiresInMs (token) {
  const { exp } = decodeToken(token)
  return exp * 1000 - Date.now()
}

/**
 * Decodes the payload from a json web token. Does not verify.
 */
export function decodeToken (token) {
  const parts = (token || '').split('.')

  if (parts.length !== 3) {
    return {}
  }

  let payload = {}

  try {
    payload = JSON.parse(atob(parts[1]))
  } catch (e) {
    console.error('Failed to parse encoded token payload')
  }

  return payload
}

/**
 *
 */
export function getFileExtension (name) {
  const split = name.split('.')
  return (split.length > 0 ? split[split.length - 1] : '').toLowerCase()
}

/**
 * Return office uri protocol based based on file extension.
 */
function getOfficeUriProtocol (ext) {
  if (WORD_FILES.includes(ext) ||
      WORD_TEMPLATE_FILES.includes(ext)) {
    return 'ms-word'
  } else if (POWERPOINT_FILES.includes(ext) ||
             POWERPOINT_TEMPLATE_FILES.includes(ext)) {
    return 'ms-powerpoint'
  } else if (EXCEL_FILES.includes(ext) ||
             EXCEL_TEMPLATE_FILES.includes(ext)) {
    return 'ms-excel'
  } else {
    return ''
  }
}

export function getItemIcon (item) {
  const ext = getFileExtension(item?.name)
  if (item.type === 'file') {
    if (WORD_FILES.includes(ext)) {
      return <Word />
    } else if (WORD_TEMPLATE_FILES.includes(ext)) {
      return <Word width={20} height={20} />
    } else if (EXCEL_FILES.includes(ext)) {
      return <Excel width={20} height={20} />
    } else if (EXCEL_TEMPLATE_FILES.includes(ext)) {
      return <Excel width={20} height={20} />
    } else if (POWERPOINT_FILES.includes(ext)) {
      return <Powerpoint width={20} height={20} />
    } else if (POWERPOINT_TEMPLATE_FILES.includes(ext)) {
      return <Powerpoint width={20} height={20} />
    }
    return null
  }
  return <Folder width={24} height={24} />
}

/**
 * Return true if extension is a template
 */
export function isTemplateFile (ext) {
  return TEMPLATE_FILES.includes(ext)
}

/**
 * Build a url using origin of scRoot together with
 * ServerRelativeUrl
 */
export function buildFileItemUrl (scRoot, file) {
  const parsed = parseUrl(scRoot)
  if (typeof file.ServerRelativeUrl === 'string') {
    return encodeURI(`${parsed.origin}${file.ServerRelativeUrl}`)
  } else {
    return ''
  }
}

/**
 * Converts keys and values from an object to a query string.
 */
export function toQueryString (params) {
  const query = Object.keys(params).map(key => {
    const value = params[key]
    if (typeof value === 'string') {
      return value ? `${key}=${wrapWin1252(encodeURI(value))}` : ''
    } else {
      return `${key}=${wrapWin1252(encodeURI(value))}`
    }
  }).filter(Boolean).join('&')
  return query ? `?${query}` : ''
}

function wrapWin1252 (str) {
  return replace(str)
}
/**
 * Open a document uri based on file type using the office uri scheme.
 * uri: full url to document or template
 * params: optional object which will passed as parameters to templates
 * **NOTE** Currently disabling query parameters for opening templates
 * on Mac!
 */
export function openDocument (uri, params) {
  const ext = getFileExtension(uri)
  const protocol = getOfficeUriProtocol(ext)
  if (protocol) {
    const isTemplate = isTemplateFile(ext)
    const command = isTemplate ? 'nft' : 'ofe'
    console.log('toQueryString(params)`', `${toQueryString(params)}`)
    const query = isTemplate && params && isWindows() ? `${toQueryString(params)}` : ''
    const url = `${protocol}:${command}|u|${uri}${query}`
    console.info('opening document', url)
    window.location.href = url
    // window.history.replaceState({}, document.title, "/");
  } else {
    console.info('could not find a suitable application for', uri)
  }
}

/**
 * Returns true if there is a matching office protocol for the
 * file type on the url.
 */
export function canOpenDocument (uri) {
  if (typeof uri === 'string') {
    return !!getOfficeUriProtocol(getFileExtension(uri))
  } else {
    return false
  }
}

/**
 * Function for figuring out if an item should be selected in a
 * tree view
 */
export function isSelected (selected) {
  return (item) => {
    if (selected) {
      return item.type === selected.type && item.id === selected.id
    } else {
      return false
    }
  }
}

/**
 * Categories are really document libraries under the parent department
 */
export function getItemUrl (item) {
  if (item.type === 'category') {
    // TODO only use item.collection_url once database views have changed!
    return item.collection_url || item.collection.url
  } else {
    return item.url
  }
}

/**
 * Category document library uses category.id. All other items use 'files'
 * as their document library root.
 */
export function getItemRoot (item) {
  if (item.type === 'category') {
    return item.id
  } else if (item.type === 'templates') {
    return 'pdsMallar'
  } else {
    return 'files'
  }
}

/**
 * Waits delay milliseconds.
 * Returns a promise.
 */
export function wait (delay) {
  return new Promise(resolve => setTimeout(resolve, delay))
}

/**
 * Return true if this is a Windows machine.
 */
export function isWindows () {
  return navigator.platform.startsWith('Win')
}

/**
 * Return true if this is a Mac machine.
 */
export function isMac () {
  return navigator.platform.startsWith('Mac')
}
